const loaders = [];

export function addLoader(func) {
  loaders.push(func);
}

export function execute(node) {
  loaders.forEach(function(loader) {
    loader.call(null, node);
  });
}

export function loadScript(scriptID, url) {
  return new Promise(function(resolve, reject) {
    const existingScript = document.querySelector(
      "script[data-script='" + scriptID + "']"
    );
    if (existingScript) {
      resolve();
    } else {
      const script = document.createElement("script");
      script.onload = resolve;
      script.onerror = reject;
      script.dataset.script = scriptID;
      script.async = true;
      script.src = url;
      document.body.appendChild(script);
    }
  });
}

import { addLoader } from "modules/loaders";
import { slideToggle } from "modules/utils";

function removeFlash(node) {
  slideToggle(node, 200, function() {
    node.parentNode.removeChild(node);
  });
}

export function addMultipleFlashes(rawMessages) {
  const messages = Object.entries(rawMessages);
  for (const [type, message] of messages) {
    addFlash(type, message);
  }
}

export function addFlash(type, message) {
  let disappearTime = 5000;
  let messageText = null;
  let button = null;

  if (typeof message == "object") {
    messageText = message.message;
    button = message.button;
  } else {
    messageText = message;
  }

  const parent = document.querySelectorAll("ul.flashMessages")[0];

  const node = document.createElement("LI");
  node.style.display = "none";
  node.classList.add("flashMessages__message--" + type);

  const textNode = document.createElement("p");
  textNode.appendChild(document.createTextNode(messageText));
  node.appendChild(textNode);

  if (button && button.url) {
    const buttonContainer = document.createElement("div");
    buttonContainer.classList.add("flashMessages__buttonContainer");
    const buttonLink = document.createElement("a");
    buttonLink.href = button.url;
    buttonLink.appendChild(
      document.createTextNode(button.text || "More details")
    );
    buttonLink.classList.add("flashMessages__button");
    buttonLink.classList.add("button");
    buttonLink.classList.add("button--small");
    buttonContainer.appendChild(buttonLink);
    node.appendChild(buttonContainer);
    disappearTime = 10000;
  }

  parent.prepend(node);
  slideToggle(node, 200);

  const timer = setTimeout(function() {
    removeFlash(node);
  }, disappearTime);

  node.addEventListener("click", function(event) {
    clearTimeout(timer);
    removeFlash(node);
  });
}

function loadFlashesFromDOM(node) {
  const elements = document.querySelectorAll("script.flashMessage");
  for (const element of elements) {
    let message = element.dataset["message"];
    const type = element.dataset["type"];
    const buttonText = element.dataset["buttonText"];
    const buttonURL = element.dataset["buttonUrl"];

    if (buttonURL) {
      message = {
        message: element.dataset["message"],
        button: { url: buttonURL, text: buttonText },
      };
    }

    addFlash(type, message);
  }
}

addLoader(loadFlashesFromDOM);

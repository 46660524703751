import { addLoader } from "modules/loaders";
import Tribute from "tributejs";

let people = null;

function fetchUsers() {

  if (people !== null) {
    return Promise.resolve(people);
  }

  return fetch("/people.json?short=1")
    .then((response) => response.json())
    .then((data) => {
      people = data;
      return data;
    });

}

function showMentions() {

  const box = document.querySelectorAll('.js-mentions');

  if (box.length) {

      fetchUsers()
      .then(users => {

        const tribute = new Tribute({
          values: users,
          selectTemplate: function(item) {
            return `@${item.original.permalink}`;
          },
          menuItemTemplate: function(item) {
            return `<span>${item.original.permalink}</span> ${item.original.name}`;
          },
          lookup: "name",
        });

        tribute.attach(box);
      });
  }
}

addLoader(showMentions);

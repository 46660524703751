import { addLoader } from "modules/loaders";
import SlimSelect from "slim-select";

function multiSelect() {

  const menus = document.querySelectorAll('.js-multi-select');
  menus.forEach(function (menu) {
    const placeholder = menu.dataset.placeholder;
    new SlimSelect({
      select: menu,
      allowDeselectOption: true,
      showSearch: true,
      placeholder: placeholder,
      deselectLabel: '<span class="ss-multi-select-delete"></span>'
    })
  });


}

addLoader(multiSelect)

import { addLoader } from "modules/loaders";
import { EmojiButton } from '@joeattardi/emoji-button';

function setupEmojiButton() {
  const trigger = document.querySelector('.emojiPicker__preview');
  if (trigger) {
    const parent = trigger.parentElement;
    const picker = new EmojiButton();
    picker.on('emoji', selection => {
      console.log(parent.querySelector('.emojiPicker__input').value)
      trigger.innerHTML = selection.emoji;
      parent.querySelector('.emojiPicker__input').value = selection.emoji;
    });
    trigger.addEventListener('click', () => picker.togglePicker(trigger));
  }
}

addLoader(setupEmojiButton);
